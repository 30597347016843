/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
    padding: 20px 15px 30px;
    background-color: $color-white;
    text-align: center;
}





.c-pagination__inner {
    max-width: 1170px;
    margin: 0 auto;
    border-top: 1px solid #dadada;
}

.c-pagination__info {
    padding: 20px 0 0;
}





.c-pagination__progressbar {
    position: relative;
    max-width: 200px;
    height: 2px;
    margin: 0 auto 15px;
    border-radius: 3px;
    background: #e2e2e2;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, .3);

    > span {
        position: relative;
        display: block;
        overflow: hidden;
        height: 100%;
        border-radius: 3px;
        background-color: $color-green;
    }
}
