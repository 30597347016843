/* ==========================================================================
   #PAGE-CONTAINER
   ========================================================================== */

.c-page-container {
    position: relative;
    height: 100%;
    background: #222;

    .has-sidebar-open & {
        overflow: hidden;
    }
}




.c-page-container__overlay {
    background: rgba(0, 0, 0, .5);
    content: '';
    opacity: 0;
    transition: opacity .5s;
    z-index: -1;

    .has-sidebar-open & {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        transition: opacity .5s;
        z-index: 1000;
    }
}
