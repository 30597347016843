/* ==========================================================================
   #FIELD
   ========================================================================== */
   // scss-lint:disable ImportantRule

.c-field {
    padding-bottom: 16px;
    position: relative;

    &.tel {
        padding-bottom: 30px;
    }
}

.c-field--checkbox {
    margin-bottom: 15px;
}





.c-field__label {
    text-transform: uppercase;
    color: #232627;
    display: block;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    text-transform: uppercase;
    font-family: $heading_font_family;
    margin: 0;


    abbr {
        text-decoration: none;
        border-bottom: 0;
    }

    .c-field.optional & {
        &::after {
            content: ' optional';
            text-transform: none;
            color: #626468;
            font-size: 10px;
            font-weight: normal;
            letter-spacing: 0.28px;
            margin-left: 10px;
            line-height: 10px;
            opacity: 0.5;
            font-family: $sans_font_family;
        }
    }
}





.c-field__checkbox-label {
    display: flex;
    align-items: center;
    vertical-align: top;
    position: relative;
    line-height: 1;
}





.c-field__input {
    position: relative;
    display: block;
    outline: 0;
    margin: 0;
    font-size: 16px;
    width: 100%;
    background-color: #f5f7fa;
    border: 1px solid #c9ccd3;
    border-radius: 2px;
    color: #131314;
    height: 36px;
    padding: 8px 12px;
    appearance: none;

    &::placeholder {
        color: $color-black--20;
    }

    .c-field--has-errors & {
        border-color: $color-red;
        padding-bottom: 7px;
        border-bottom-width: 2px;
    }
}





.c-field__checkbox-text {
    font-size: 12px;
    flex: 1;
    line-height: 1.3;
}





.c-field__checkbox {
    appearance: none;
    cursor: pointer;
    height: 25px;
    width: 25px;
    display: block;
    padding: 0;
    line-height: 25px !important;
    border-radius: 5px;
    background-color: $color-white;
    color: $color-white;
    text-align: center;
    border: solid 2px rgba(0, 0, 0, 0.145);
    outline: 0 !important;
    margin: 0 10px 0 0 !important;

    &::before {
        content: '';
        font-family: 'Ionicons';
    }

    &:checked {
        border-color: $color-green;

        &::before {
            content: '\f122';
            color: $color-green;
            position: relative;
            top: -1px;
        }
    }
}





.c-field__error {
    position: absolute;
    color: $color-red;
    padding: 1px 0 0;
    font-size: 12px;
    bottom: 0;
}
