/* ==========================================================================
   #COUNTDOWN
   ========================================================================== */

.c-countdown {
    margin: 0 auto;
    padding: 0 10px;
    text-decoration: none;
    border: 1px solid #a2aab1;
    border-radius: 50px;
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    width: 160px;

    th {
        font-size: 8px;
        text-align: center;
        text-transform: uppercase;
        color: #777;
        width: 35px;
        border-right: 1px solid #e3e3e3;
        padding: 0 0 5px;

        &:last-child {
            border-right: 0;
        }
    }

    td {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 14px;
        width: 35px;
        border-right: 1px solid #f0f0f0;
        padding: 5px 0 0;

        &:last-child {
            border-right: 0;
        }
    }
}





.c-countdown__days {
    font-size: 9px;
    font-weight: bold;
    color: #777;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 10px;
    line-height: 1;

    strong {
        color: $color-green;
        font-size: 14px;
        font-weight: bold;
        padding: 0 3px;
    }
}
