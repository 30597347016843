.welcome-join {
    background-color: $color-almostWhite;
    padding: 100px 20px 80px;

    @media (min-width: 768px) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.welcome-join__form {
    margin: 0 auto;
    max-width: 650px;
    background: $color-white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.10);

    @media (min-width: 768px) {
        padding: 60px;
    }
}

.welcome-join__title {
    font-family: $heading_font_family;
    color: $color-vader;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 25px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.15px;

    @media (min-width: 768px) {
        letter-spacing: -0.7px;
        font-size: 28px;
        line-height: 30px;
    }

    @media (min-width: 1200px) {
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -0.22px;
    }
}

.welcome-join__subtitle {
    font-family: $heading_font_family;
    color: $color-mofoGreen;
    text-align: center;
    font-weight: normal;
    margin-bottom: 35px;
    letter-spacing: -0.22px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 60px;
    }

    @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 24px;
    }
}
