/* ==========================================================================
   #SITE-HEADER
   ========================================================================== */

.c-site-header {
    background-color: #111;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    @media print {
        display: none;
    }
}

.c-site-header__inner {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    flex-wrap: wrap;

    @media only screen and (min-width: 980px) {
        width: 90vw;
    }
}

.c-site-header__left {
    text-align: left;
}

.c-site-header__right {
    text-align: right;
}

.c-site-header__logo {
    padding: 10px 0 2px;

    svg {
        width: 108px;
        height: 27px;
    }

    &:focus {
        text-decoration: none;
    }

    &.focus-visible {
        outline: 2px solid #fff;
    }

    @media only screen and (min-width: 768px) {
        padding: 10px 0 0;

        svg {
            width: 152px;
            height: 38px;
        }
    }

    .has-sidebar-open & {
        opacity: 0.5;
    }
}

.c-site-header__links {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    flex: 1;
    transition: opacity 0.5s;

    .has-sidebar-open & {
        opacity: 0.5;
    }
}

.c-site-header__link {
    display: inline-block;
    vertical-align: text-top;
    display: inline-block;

    a,
    button {
        position: relative;
        text-align: center;
        padding: 0 5px;
        transition: color 0.3s ease;
        outline: 0;
        background-color: transparent;
        border: 0;
        color: $color-white;
        display: block;

        @media only screen and (min-width: 768px) {
            padding: 0 5px 5px;
        }

        &:focus {
            text-decoration: none;
        }

        &.focus-visible {
            text-decoration: none;
            outline: 2px solid $color-white;
        }

        &:hover {
            text-decoration: none;
            color: $color-green;
        }
    }

    i {
        display: block;
        font-size: 22px;
        line-height: 1;

        @media only screen and (min-width: 768px) {
            font-size: 28px;
        }
    }

    span {
        font-size: 12px;
        margin-top: -4px;
        display: none;

        @media only screen and (min-width: 768px) {
            display: block;
        }
    }
}

.c-site-header__link--menu-toggle {
    padding-right: 8px;

    button {
        color: $color-white;

        &:hover {
            color: $color-green;
        }
    }
}

.c-site-header__link--account,
.c-site-header__link--login,
.c-site-header__link--call-us {
    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.c-site-header__link--login {
    a {
        opacity: 0.7;
    }
}

.c-site-header__alert-count {
    position: absolute;
    right: -7px;
    top: -7px;
    display: block;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;
    background: #d51c5c;
    color: $color-white;
    border-radius: 50%;
    pointer-events: none;

    @media only screen and (min-width: 768px) {
        top: -5px;
        right: -10px;
    }
}

.c-site-header__bottom {
    width: 100%;
    display: block;
    position: relative;

    @media only screen and (max-width: 768px) {
        padding: 4px 10px 0 0;
        margin: 0 -10px;
        width: calc(100% + 20px);

        &:before {
            background: linear-gradient(
                to right,
                rgba(#111, 0) 0,
                #111 100%
            );
            bottom: 0;
            content: "";
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            width: 64px;
            z-index: 10;
        }
    }
}
