/* ==========================================================================
   #TABS
   ========================================================================== */

.c-tabs__items {
    width: 100%;
    background-color: $color-white;
    float: left;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
    list-style: none;
    border-bottom: 1px solid $color-grey--medium;

    @media only screen and (min-width: 768px) {
        padding-right: 40px;
        padding-left: 40px;
    }

    @media only screen and (min-width: 1024px) {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media only screen and (min-width: 1200px) {
        padding-right: 40px;
        padding-left: 40px;
    }
}





.c-tabs__item {
    margin: 0 0 -1px;
    float: left;
    width: 50%;
}





.c-tabs__button {
    display: block;
    font-size: 15px;
    font-weight: 400;
    display: block;
    margin: 0;
    padding: 12px 20px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    color: #555;
    outline: 0;
    background-color: $color-white;
    touch-action: manipulation;
    width: 100%;
    border: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    font-weight: normal;
    font-family: $heading_font_family;

    &:hover {
        color: #333;
        text-decoration: underline;
    }
}

.c-tabs__button--is-selected {
    background-color: $color-white;
    color: #48a90f;
    border: 1px solid $color-grey--medium;
    border-bottom: 1px solid transparent;

    &:hover {
        color: #48a90f;
        text-decoration: none;
    }
}





.c-tabs__panel {
    display: none;
    padding: 20px;
    flex-direction: column;
    flex: 1;

    @media only screen and (min-width: 768px) {
        padding: 20px 40px 40px;
    }

    @media only screen and (min-width: 1024px) {
        padding: 20px;
        min-height: 496px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 20px 40px 40px;
    }
}

.c-tabs__panel--is-open {
    display: flex;
}
