.why-mobile {
    text-align: center;
    padding: 35px 20px 45px;

    @media (min-width: 768px) {
        display: none;
    }

    .cta-button {
        margin: 0;
    }
}

.why-mobile__section-title {
    font-family: $heading_font_family;
    color: $color-mofoGreen;
    text-align: center;
    font-weight: normal;
    margin-bottom: 25px;
    line-height: 24px;
    letter-spacing: -0.22px;
    font-size: 18px;
}

.why-mobile__images {
    margin: 0 -10px 20px;
    padding: 0;
    list-style: none;
    display: flex;
}

.why-mobile__image {
    padding: 0 10px;
    opacity: 0.3;

    img {
        width: 100%;
    }
}

.why-mobile__image--is-active {
    opacity: 1;
}

.why-mobile__title {
    font-family: $heading_font_family;
    color: $color-vader;
    font-size: 20px;
    letter-spacing: -0.12px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.why-mobile__description {
    display: none;
    font-size: 14px;
    letter-spacing: -0.19px;
    line-height: 20px;
}

.why-mobile__description--is-active {
    display: block;
    min-height: 120px;

    p {
        margin: 0;
    }
}
