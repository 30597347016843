/* ==========================================================================
   #OFFER
   ========================================================================== */

.c-offer {
    position: relative;
    margin-bottom: 30px;
    padding: 0 15px;

    @media only screen and (min-width: 768px) {
        width: 50%;
    }

    @media only screen and (min-width: 1200px) {
        width: 33.33333%;
    }

    .c-offers--full & {

        @media only screen and (min-width: 992px) {
            width: 33.33333%;
        }

        @media only screen and (min-width: 1200px) {
            width: 25%;
        }
    }
}

.c-offer--unavailable {
    opacity: .75;
    filter: grayscale(100%);
}





.c-offer__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background: $color-white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
}





.c-offer__image-link {
    display: block;
    border: 0;
    flex-shrink: 0;
    position: relative;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    picture {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
    }
}

.c-offer__image--alt {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &:hover,
    &.c-offer__image--alt-infocus {
        opacity: 1;
    }
}

.c-offer__image {
    max-width: 100%;
    vertical-align: middle;
}





.c-offer__header {
    position: relative;
    padding: 6px 15px 0;
    text-align: center;
    margin-top: auto;
}





.c-offer__title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: inherit;
    line-height: 1.4;
    min-height: 62px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
}

.c-offer__title-link {
    display: block;
    padding: 9px 0 9px;
    text-decoration: none;
    color: $color-black;
    background-color: $color-white;

    &:focus,
    &:hover {
        text-decoration: none;
        color: $color-green;
    }

    .c-offer--unavailable & {
        color: #474747;
    }
}







.c-offer__attributes {
    font-size: 10px;
    font-weight: bold;
    margin: 0;
    padding: 20px 0;
    list-style: none;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    min-height: 54px;

    li {
        padding: 0;
        text-align: center;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        padding-top: 2px;
    }

    svg {
        width: 14px;
        height: 14px;
        opacity: 0.65;
        margin-right: 5px;
        fill: $color-green;

        .c-offer--unavailable & {
            fill: #777;
        }
    }
}





.c-offer__details {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: auto;
    padding: 0 20px;
    text-align: center;
}
