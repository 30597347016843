
.c-info-box {
    background-color: $color-white;
    position: relative;

    @media only screen and (min-width: 768px) {
        display: flex;
        min-height: 143px;
        position: absolute;
        bottom: 45px;
        left: 50%;
        width: 738px;
        margin-left: -369px;
        border: 1px solid #d9d9d9;
        margin-bottom: 0;
    }

    article.offer.banner.dark & {
        background-color: #131313;
        border-color: #111;
    }

    article.offer.banner.straight & {
        @media only screen and (min-width: 1200px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 544px;
            position: absolute;
            top: 50%;
            margin-top: -272px;
            left: 50%;
            margin-left: 155px;
            width: 415px;
            margin-bottom: 0;
        }
    }
}

.c-info-box__header {
    display: flex;
    flex-direction: column;
    padding: 15px 50px;
    align-items: center;

    @media only screen and (min-width: 768px) {
        justify-content: center;
        flex: 1 1 0px;
        border-right: 1px solid #d9d9d9;
        padding: 15px;
    }

    h1, a {
        color: #000;
        text-align: center;
    }

    article.offer.banner.dark & {
        @media only screen and (min-width: 768px) {
            border-color: #111;
        }

        h1, a {
            color: $color-white;
        }
    }

    h1 {
        font-size: 15px;
        padding-right: 0;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: normal;
    }

    .attributes {
        margin-bottom: 0!important;
        text-align: center;
        position: static!important;
        left: auto!important;
        width: auto!important;
        top: auto!important;
    }

    article.offer.banner.straight & {
        @media only screen and (min-width: 1200px) {
            border-right: 0;
            flex: 0 auto;
            padding-right: 60px;
            padding-left: 60px;
        }
    }
}

.c-info-box__countdown {
    padding-top: 15px;
    max-width: 200px;

    .countdown {
        position: static!important;
        width: auto;
        border-right: 0!important;

        article.offer.banner.straight & {
            @media only screen and (min-width: 1200px) {
                position: absolute!important;
                bottom: 0px;
                left: 0px;
                right: 0px;
                padding: 22px 45px 14px;
                height: 100px;
            }
        }
    }

    .no-countdown & {
        display: none;
    }
}

.c-info-box__details {
    @media only screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1 1 0px;
        border-right: 1px solid #d9d9d9;
        padding: 20px;
    }

    article.offer.banner.dark & {
        @media only screen and (min-width: 768px) {
            border-color: #111;
        }
    }

    article.offer.banner.straight & {
        @media only screen and (min-width: 1200px) {
            border-right: 0;
            flex: 0 auto;
            padding: 0 20px;
        }
    }
}

.c-info-box__footer {
    padding: 15px;

    @media only screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex: 1 1 0px;
    }
   
    .discount {
        @media only screen and (max-width: 768px) {
            top: 0!important;
            transform: translateY(-110%);
        }
    }

    article.offer.banner.straight & {
        @media only screen and (min-width: 1200px) {
            flex: 0 auto;
        }
    }

    > div,
    > form {
        width: 100%;
    }

    .btn {
        padding: 0.9em 1em;
        padding-bottom: 0.9em;
        border-radius: 100px;
        font-family: $heading_font_family;
        font-weight: 300;

        span {
            font-family: $sans_font_family;
            display: block;
            line-height: 1em;
            font-size: 11px;
            color: rgba(255,255,255,0.7);
            margin-top: -1px;
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (min-width: 768px) {
                font-size: 12px;
            }
        }

        &.has-subtext {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
    }

    .btn-success {
        background-color: $color-greenLantern;
        border-color: $color-greenLantern;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover,
        &:focus {
            background-color: lighten($color-greenLantern, 1%);
            border-color: lighten($color-greenLantern, 1%);
        }
    }
}
