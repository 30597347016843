.c-loyalty-headline {
    display: flex;
    align-items: baseline;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

    &__subheading {
        margin-left: 20px;
        font-size: 20px;
        line-height: 30px;

        @media only screen and (max-width: 768px) {
            margin-left: 0;
            margin-bottom: 20px;
        }
    }
}

.c-loyalty-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid #eaeaea;
    overflow: hidden;

    &--split {
        flex-direction: row;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__loyalty-status {
        padding: 30px 0;
    }

    &__heading {
        font-family: $heading_font_family;
        color: $color-greenLantern;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;

        &--level {
            color: #1d1d1f;
            font-weight: bold;
        }
    }

    &__description {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }

    &__meta-list {
        display: flex;
        justify-content: space-between;
        min-height: 120px;

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    &__meta-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        min-height: 95px;

        &:last-of-type {
            border-right: none;
        }

        &--credits {
            flex-grow: 3;
            @media only screen and (max-width: 768px) {
                width: 50%;
            }
        }

        &--points {
            flex-grow: 2;
            @media only screen and (max-width: 768px) {
                width: 50%;
            }
        }

        &--next-review {
            flex-grow: 4;
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &--next-level {
            flex-grow: 4;
            @media only screen and (max-width: 768px) {
                width: 100%;
                padding: 40px;
            }
        }
    }

    &__meta-item-value {
        font-family: $heading_font_family;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: $color-greenLantern;

        &--date {
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.416667px;
            color: black;
        }

        &--disabled {
            color: #e1e1e1;
        }
    }

    &__meta-item-heading {
        font-family: $sans_font_family;
        font-size: 14px;
        line-height: 24px;
    }

    &__block {
        padding: 40px;
        width: 100%;

        @media only screen and (max-width: 768px) {
            padding: 20px;
        }

        ul {
            padding-left: 15px;
            list-style: none;

            li {
                padding: 15px 7px;
            }

            li::before {
                content: "\2022";
                color: $color-greenLantern;
                font-weight: bold;
                font-size: 15px;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }

            &.two-col {
                columns: 2;
                column-rule: 1px solid $color-neonGreen;

                li {
                    margin-left: 35px;
                }
            }
        }

        &--accent {
            color: white;
            background: $color-greenLantern;
            overflow: hidden;

            ul > li::before {
                color: $color-neonGreen;
            }
        }
    }

    &__block-heading {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}
