.why {
    text-align: center;
    display: none;

    @media (min-width: 768px) {
        padding: 50px 30px 20px;
        display: block;
        max-width: 1160px;
        margin: 0 auto;
    }

    @media (min-width: 1024px) {
        padding: 90px 30px;
    }
}

.why-terms {
    font-size: 12px;
    margin-top: 30px;
}

.why__section-title {
    font-family: $heading_font_family;
    color: $color-mofoGreen;
    text-align: center;
    font-weight: normal;
    margin-bottom: 35px;
    letter-spacing: -0.22px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.why__list {
    display: grid;
    padding-left: 0;
    list-style: none;
    justify-content: space-between;
    display: flex;
    margin-bottom: 45px;

    @media (min-width: 768px) {
        margin-right: -10px;
        margin-left: -10px;
    }

    @media (min-width: 1200px) {
        margin-right: -20px;
        margin-left: -20px;
    }
}

.why__list-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #2f3032;
    flex: 1;

    @media (min-width: 768px) {
        font-size: 14px;
        letter-spacing: -0.19px;
        line-height: 20px;
        padding: 0 10px;
    }

    @media (min-width: 1200px) {
        font-size: 16px;
        letter-spacing: -0.22px;
        line-height: 24px;
        padding: 0 20px;
    }

    p {
        margin: 0;
    }
}

.why__title {
    font-family: $heading_font_family;
    color: $color-vader;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 25px;
    width: 100%;

    @media (min-width: 768px) {
        font-size: 24px;
        letter-spacing: -0.15px;
        line-height: 28px;
        width: 180px;
    }

    @media (min-width: 1200px) {
        font-size: 36px;
        letter-spacing: -0.22px;
        line-height: 40px;
        width: 265px;
    }
}

.why__image {
    width: 65%;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    max-width: 220px;

    img {
        width: 100%;
    }
}
