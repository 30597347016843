/* ==========================================================================
   #COLORS
   ========================================================================== */

/**
 * In this file, we take the literal colors from our palette and define them
 * against variables that we can utilise anywhere throughout the project.
 */





/* Text
   ====================================================================== */

$color-text:    $color-black !default;
$color-text-bg: $color-white !default;
$color-lists:   $color-black !default;





/* Buttons
   ====================================================================== */

$color-btn-primary: $color-white !default;
$color-btn-primary-bg: #48a90f !default;
$color-btn-primary-bg-hover: #43a00e !default;

$color-btn-secondary: $color-white !default;
$color-btn-secondary-bg: $color-orange !default;
$color-btn-secondary-bg-hover: lighten($color-btn-secondary-bg, 1%) !default;

$color-btn-tertiary: $color-white !default;
$color-btn-tertiary-bg: $color-blue !default;
$color-btn-tertiary-bg-hover: lighten($color-btn-tertiary-bg, 1%) !default;

$color-btn-facebook: $color-white !default;
$color-btn-facebook-bg: #3b5998 !default;
$color-btn-facebook-bg-hover: lighten($color-btn-facebook-bg, 1%) !default;

$color-btn-google: #3c4043 !default;
$color-btn-google-bg: $color-white !default;
$color-btn-google-bg-hover: lighten($color-btn-google-bg, 1%) !default;

$color-btn-danger: $color-white !default;
$color-btn-danger-bg: $color-red !default;
$color-btn-danger-bg-hover: lighten($color-btn-danger-bg, 1%) !default;
