/* ==========================================================================
#OAUTH (Doorkeeper)
========================================================================== */

.c-oauth__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f7f7f7;
    justify-content: space-between;

    .notification-bar-flash {
        width: 100%;
    }
}





.c-oauth__body {
    width: 100%;
    max-width: 420px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}





.c-oauth__footer {
    padding: 15px;
}





.c-oauth__logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;

    svg {
        width: 160px;
        height: auto;

        @media only screen and (min-width: 480px) {
            width: 180px;
        }
    }
}





.c-oauth__title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #111;
    font-weight: 400;

    @media only screen and (min-width: 480px) {
        font-size: 24px;
    }
}





.c-oauth__content {
    padding: 30px 15px 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 2px;
    background: $color-white;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, .05);

    strong {
        font-weight: bold;
        color: #333;
    }
}





.c-oauth__error {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #111;
    z-index: 9999;
}





.c-oauth__row {
    margin-bottom: 30px;
    padding-left: 47px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}

.c-oauth__row-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;

    text-align: center;

    img {
        width: 100%;
        height: 100%;
        background-color: #ccc;
        border-radius: 999px;
        margin-top: 4px;
    }

    svg {
        fill: #777;
        margin-left: -3px;
    }
}

.c-oauth__row-text {
    color: #777;
    line-height: 1.5em;
}





.c-oauth__note {
    font-size: 13px;
    padding: 0 10px;
    margin-bottom: 13px;
    text-align: center;
}





.c-oauth__cancel {
    text-align: center;
    font-size: 13px;
    padding-top: 20px;
    margin-bottom: 60px;

    input[type=submit] {
        border: 0;
        background-color: transparent;
        outline: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}





.c-oauth__message {
    line-height: 1.5;
    margin-bottom: 15px;
}
