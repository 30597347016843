/* ==========================================================================
   #ACCORDION
   ========================================================================== */

.c-accordion {
    border-top: 0;
    margin-bottom: 20px;
}


.c-accordion__buttons {
    display: flex;
    margin-bottom: 25px;

    .btn-success {
        margin-left: auto;
    }

    .btn[disabled],
    .btn:disabled,
    .btn.disabled {
        color: $color-white !important;

        &::before {
            content: '';
        }
    }
}
