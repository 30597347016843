footer.site-footer {
    margin-top: 50px;

    nav.footer-nav {
        padding: 40px 0 50px;

        @media only screen and (min-width: 768px) {
            padding: 80px 0 50px;
        }
    }

    ul.c-payment-options {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: auto;
        margin-bottom: 30px;

        @media only screen and (min-width: 768px) {
            margin-bottom: 60px;
        }

        li {
            width: 40px;
            margin: 0 5px;

            @media only screen and (min-width: 768px) {
                width: 50px;
            }
        }

        svg {
            width: 100%;
            height: auto;
        }
    }
}
