.c-search-header {
    background: $color-white;
    background: linear-gradient(180deg, #f6f6f6 0%, $color-white 15%);
    padding: 25px 15px;

    @media only screen and (min-width: 992px) {
        padding: 60px 20px 50px;
    }
}

.c-search-header h1 {
    @include hide-visually;
}

.c-search-header__inner {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 750px;
    min-height: 43px;

    @media only screen and (min-width: 992px) {
        max-width: 970px;
        min-height: 94px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1170px;
    }
}
