/* ==========================================================================
   #OFFERS
   ========================================================================== */

.c-offers {
    font-weight: 400;
    padding: 0 15px 10px;

    @media only screen and (min-width: 768px) {
        padding-right: 0;
        padding-left: 0;
    }
}





.c-offers__inner {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 768px) {
        max-width: 750px;
    }

    @media only screen and (min-width: 992px) {
        max-width: 970px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1170px;
    }

}





.c-offers__filter {
    padding: 0 15px;
    order: 2;
    width: 100%;

    @media only screen and (min-width: 768px) {
        width: 33.33333%;
    }

    @media only screen and (min-width: 1200px) {
        width: 25%;
    }
}



.c-offers__content {
    transition: opacity .25s ease-in-out;
    order: 3;
    width: 100%;

    @media only screen and (min-width: 768px) {
        width: 66.66666%;
    }

    @media only screen and (min-width: 1200px) {
        width: 75%;
    }

    .c-offers--full & {
        width: 100%;
    }
}

.c-offers__content--loading {
    opacity: .3;
}


.c-offers__content-inner {
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
    }
}
