/* ==========================================================================
   #PRICE
   ========================================================================== */

.c-price-details {
    width: 100%;
    margin-bottom: 16px;
}





.c-price {
    font-family: $heading_font_family;
    font-size: inherit;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}





.c-price__rrp {
    font-size: 15px;
    position: relative;
    color: $color-red;
    margin-right: 6px;
    padding-top: 1px;
    font-weight: 300;
}





.c-price__total {
    font-size: 20px;
    font-weight: 300;
    color: #131314;

    .c-offer--unavailable & {
        color: #474747;
    }

    sup {
        font-size: 70%;
        position: relative;
        top: -0.25em;
        vertical-align: baseline;
    }
}





.c-price-details__note {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #777;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    width: 100%;

    &::before {
        border-top: 1px solid #f0f0f0;
        content: '';
        margin: 0 auto;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 95%;
        z-index: -1;
    }

    span {
        background: #fff;
        padding: 0 10px;
    }
}
