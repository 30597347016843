/* ==========================================================================
   #FILTER-OPTION
   ========================================================================== */

.c-filter-option {
    color: $color-grey--dark;
    padding: 11px 20px 11px 15px;
    display: flex;
    transition: all .3s ease-out;
    align-items: center;
    background-color: transparent;
    outline: 0;
    width: 100%;
    text-align: left;
    position: relative;
    border: 0;

    span {
        display: inline-block;
        margin-left: auto;
        color: lighten($color-grey--dark, 30%);
        padding-left: 15px;
        font-size: 10px;
        color: #b0b0b0;
    }

    &::after {
        transition: all .3s ease-out;
        content: '';
        width: 12px;
        height: 12px;
        border: 1px solid $color-white;
        background-color: $color-white;
        outline: 1px solid $color-grey--medium;
        display: inline-block;
        margin-left: auto;
        margin-top: 1px;
    }

    &:focus {
        color: $color-grey--dark;
        text-decoration: none;
    }

    &:hover {
        background-color: $color-grey--light;
        color: #333;
        text-decoration: none;

        &::after {
            outline: 1px solid $color-grey--dark;
            background-color: #ebebe4;
            border-color: #ebebe4;
        }
    }


    &[aria-pressed='true'] {
        &::before {
            content: '\f122';
            font-family: 'Ionicons';
            position: absolute;
            right: 21px;
            top: 12px;
            color: $color-grey--dark;
        }

        &::after {
            border-color: $color-white;
            background-color: $color-white;
            outline: 1px solid $color-grey--dark;
        }
    }

    &[aria-disabled='true'] {
        opacity: .4;
        cursor: default;

        &:hover {
            background: none;
            color: $color-grey--dark;
            border-color: $color-grey--medium;

            &::after {
                border-color: $color-white;
                background-color: $color-white;
                outline: 1px solid $color-grey--medium;
            }
        }
    }
}
