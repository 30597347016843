.c-back-to-top {
    display: block;
    background: $color-black;
    width: 40px;
    height: 34px;
    position: fixed;
    bottom: 200px;
    right: 0;
    z-index: 10000;
    transition: transform 0.4s ease-in-out;
    transform: translateX(100%);
    color: $color-white;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border: 1px solid $color-white;
    border-right: 0;
    opacity: 0.8;
    padding: 5px 0 0 10px;
    animation-duration: 1s;
    animation-fill-mode: both;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.c-back-to-top--is-visible {
    transform: translateX(0);
}
