/* ==========================================================================
   #QUICK-LINKS
   ========================================================================== */

.c-quick-links {
    padding: 0 5px;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
        display: inline-block;
        align-self: center;
    }

    width: 100%;

    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }

    @media only screen and (min-width: 768px) {
        padding: 5px 0;
    }
}

.c-quick-links__link {
    color: $color-white;
    font-weight: 400;
    font-size: 11px;
    padding: 6px 8px 12px 8px;
    display: block;
    text-align: center;
    position: relative;
    transition: color 0.3s ease;
    white-space: nowrap;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }

    @media only screen and (min-width: 768px) {
        font-size: 12px;
        padding: 10px 8px;
    }

    @media only screen and (min-width: 980px) {
        font-size: 14px;
    }

    &:focus {
        text-decoration: none;
    }

    &.focus-visible {
        outline: 2px solid $color-white;
        text-decoration: none;
        color: $color-white;
    }

    &:hover,
    &--active {
        text-decoration: none;
    }

    &:active {
        color: $color-white;
    }

    &:hover {
        color: $color-white;

        @media only screen and (min-width: 768px) {
            color: $color-green;
        }
    }

    &--active,
    &--active:active,
    &--active:hover {
        color: $color-green;
    }
}
