.c-cart {
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px 60px;
    box-sizing: content-box;
}

.c-cart__content {

    @media only screen and (min-width: 992px) {
        display: flex;
    }
}

.c-cart__main {
    flex: 1 1 100%;

    @media only screen and (min-width: 992px) {
        align-self: flex-start;
        margin-right: 40px
    }
}

.c-cart__aside {
    @media only screen and (min-width: 992px) {
        width: 360px;
        flex-shrink: 0;
        align-self: flex-start;
    }
}

.c-cart__box {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: $color-white;
    overflow: hidden;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) {
        margin-bottom: 40px;
    }

    &.c-cart__box--mobile-only {
        @media only screen and (min-width: 992px) {
            display: none;
        }
    }

    &.c-cart__box--desktop-only {
        display: none;

        @media only screen and (min-width: 992px) {
            display: block;
        }
    }
}

.c-cart__table {
    border-radius: 5px;
    width: 100%;

    thead {
        display: none;

        @media only screen and (min-width: 768px) {
            display: table-header-group;
        }
    }

    th {
        background-color: $color-almostWhite;
        font-size: 12px;
        color: $color-dorianGray;
        letter-spacing: -0.17px;
        line-height: 18px;
        font-weight: bold;
        padding: 10px 25px;
        border-top: 2px solid $color-white;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;
        
        &:first-child {
            border-top-left-radius: 5px;
            border-left: 2px solid $color-white;
            text-align: left;
        }
        
        &:last-child {
            border-top-right-radius: 5px;
            border-right: 2px solid $color-white;
            text-align: right;
        }
    }

    td {
        font-size: 14px;
        color: $color-dorianGray;
        letter-spacing: -0.19px;
        border-top: 1px solid #e2e2e2;
        line-height: 1.42;


        &.c-order-item__details {
            display: block;
            padding: 20px;

            @media only screen and (min-width: 768px) {
                padding: 20px 15px;
            }
        }

        &.c-order-item__quantity {
            display: inline-block;
            width: 50%;
            text-align: left;
            padding: 10px 20px;

            @media only screen and (min-width: 768px) {
                display: table-cell;
                width: 112px;
                text-align: center;
                padding: 20px 15px;
            }
        }

        &.c-order-item__total {
            font-family: $heading_font_family;
            font-size: 16px;
            color: $color-vader;
            letter-spacing: -0.45px;
            font-weight: bold;
            display: inline-block;
            width: 50%;
            text-align: right;
            padding: 10px 20px;

            @media only screen and (min-width: 768px) {
                font-size: 18px;
                display: table-cell;
                width: 130px;
                padding: 20px 30px 20px 15px;
            }

        }
    }

    tbody tr:first-child td {
        @media only screen and (min-width: 768px) {
            border-top: 0;
        }
    }
}

.c-cart__button {
  font-family: $heading_font_family;
  font-size: 16px;
  outline: 0;
  border-radius: 100px;
  cursor: pointer;
  white-space: nowrap;
  padding: 11px 40px;
  color: $color-white;
  background-color: $color-greenLantern;
  border: 2px solid $color-greenLantern;
  box-sizing: border-box;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;

  @media only screen and (min-width: 992px) {
      font-size: 20px;
  }

  &:hover,
  &:focus {
    border-color: tint($color-greenLantern, 95%);
    background-color: tint($color-greenLantern, 95%);
    color: $color-white;
    text-decoration: none;
  }

  &:active {
    border-color: shade($color-greenLantern, 95%);
    background-color: shade($color-greenLantern, 95%);
  }
}

.c-cart-item {
    display: flex;
}

.c-cart-item__image {
    flex-basis: 45px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 16px;

    @media only screen and (min-width: 768px) {
        flex-basis: 80px;
    }
}

.c-cart-item__content {
    @media only screen and (min-width: 768px) {
        padding: 5px 0;
    }
}

.c-cart-item__name {
    font-family: $heading_font_family;
    font-size: 16px;
    letter-spacing: -0.45px;
    color: $color-vader;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 1.5;
    }

    &:hover,
    &:focus {
        color: $color-vader;
    }
}

.c-cart-item__qty {
    display: flex;
    align-items: center;

    > form {
        height: 24px;
    }
}

.c-cart-item__qty-total {
    width: 24px;
    line-height: 1;
    text-align: center;
    padding: 0 5px;
    box-sizing: content-box;
}

.c-cart-item__qty-btn {
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    appearance: none;
    background: none;
    width: 24px;
    height: 24px;
    fill: $color-greenLantern;

    &:disabled {
        opacity: 0.3;
        fill: $color-dorianGray;
    }
}

.c-cart-item__notes {
    margin-bottom: 10px;
}

.c-cart-item__remove-btn {
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    appearance: none;
    background: none;
    height: 24px;
    color: $color-vader;
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: -0.17px;
    
    svg {
        flex-shrink: 0;
        flex-grow: 0;
        height: 16px;
        width: 16px;
        margin-right: 5px;
        opacity: 0.5;
        fill: $color-dorianGray;
    }

    &:hover {
        text-decoration: underline;

        svg {
            opacity: 0.8;
        }
    }
}



.c-cart-banner {
    margin-bottom: 20px;
    min-height: 100px;
    display: flex;
    align-items: flex-end;

    @media only screen and (min-width: 992px) {
        margin-bottom: 40px;
    }
}

.c-cart-banner__inner {
    background-color: $color-papaSmurf;
    border-radius: 5px;
    color: $color-white;
    padding: 20px;
    position: relative;
    flex-basis: 100%;
    flex-grow: 1;

    .c-cart-banner--alternative & {
        background-color: $color-charlie;
    }

    @media only screen and (min-width: 992px) {
        padding: 20px 30px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-top-color: $color-papaSmurf;
        border-bottom: 0;
        margin-left: -15px;
        margin-bottom: -10px;

        .c-cart-banner--alternative & {
            border-top-color: $color-charlie;
        }
    }

    &::after {
        content: "";
        background-image: image-url('site/eddie-wine-glass.png');
        background-size: cover;
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 136px;
        height: 115px;

        @media only screen and (min-width: 992px) {
            right: 40px;
        }
    }
}

.c-cart-banner__inner--promo {
    background-color: #cc1283;
    color: $color-white;
    outline: 0;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $color-white;

        .c-cart-banner__text strong {
            text-decoration: none;
        }
    }

    &::before {
        border-top-color: #cc1283;
    }

    &::after {
        content: "";
        background-image: image-url('site/black-friday-deal-badge.png');
        width: 100px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);

        @media only screen and (min-width: 992px) {
            width: 100px;
            height: 100px;
        }
    }

    .c-cart-banner__title {
        max-width: 80%;

        @media only screen and (min-width: 992px) {
            max-width: unset;
        }
    }

    .c-cart-banner__text strong {
        text-decoration: underline;
    }
}

.c-cart-banner__title {
    font-family: $heading_font_family;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 1.4;
    margin: 0;
    color: $color-white;
    position: relative;

    @media only screen and (min-width: 992px) {
        font-size: 20px;
    }
}

.c-cart-banner__text {
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 1.4;
    margin: 0 160px 0 0;
    position: relative;

    strong {
        color: inherit;
        font-weight: bold;
    }

    @media only screen and (min-width: 992px) {
        font-size: 16px;
    }
}

.c-cart__back-button {
  font-family: $heading_font_family;
  display: inline-block;
  font-size: 16px;
  outline: 0;
  border-radius: 100px;
  cursor: pointer;
  padding: 11px 40px;
  white-space: nowrap;
  color: $color-darkMofo;       
  background: none;
  border: 2px solid $color-greenLantern;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  position: relative;

  &:before {
    content: "";
    width: 8px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.797 6.797a1 1 0 01.219-.625L5.641.391l1.562 1.25-4.126 5.156 4.12 5.156-1.562 1.25L1.01 7.422a1 1 0 01-.213-.625z' fill='%2348A90F' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  @media only screen and (min-width: 992px) {
      font-size: 18px;
  }

  &:hover,
  &:focus {
    border-color: tint($color-greenLantern, 95%);
    background-color: rgba(255, 255, 255, 0.25);
    text-decoration: none;
    color: $color-darkMofo;
  }
}


.express-checkout .standard-form-label {
    width: auto !important;
}
