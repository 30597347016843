/* ==========================================================================
   #MENU-BUTTON
   ========================================================================== */

.c-menu-button {
    background: none;
    border: 0;
    padding: 10px;
    outline: 0;
    margin-left: -10px;
    outline: 0;

    &.focus-visible {
        outline: 2px solid $color-white;
    }
}

.c-menu-button--close {
    position: absolute;
    top: 2px;
    right: 2px;
    padding-right: 0;
}





.c-menu-button__lines {
    display: inline-block;
    width: 25px;
    height: 2px;
    background-color: $color-white;
    border-radius: 3px;
    position: relative;
    vertical-align: top;
    margin-right: 10px;
    top: 8px;
    transition: background-color .3s ease;

    .c-menu-button--close & {
        transform: rotate3d(0, 0, 1, 45deg);
    }

    &::before,
    &::after {
        display: inline-block;
        width: 25px;
        height: 2px;
        background-color: $color-white;
        border-radius: 3px;
        position: absolute;
        left: 0;
        content: '';
        transition: background-color .3s ease;
    }

    &::before {
        top: 8px;

        .c-menu-button--close & {
            top: 0;
            transform: rotate3d(0, 0, 1, -90deg);
        }
    }

    &::after {
        top: -8px;

        .c-menu-button--close & {
            top: 0;
            transform: rotate3d(0, 0, 1, -90deg);
        }
    }

    .c-menu-button:hover & {
        background-color: $color-green;

        &::before,
        &::after {
            background-color: $color-green;
        }
    }

    .c-menu-button--close.focus-visible & {
        background-color: #fff;

        &::before,
        &::after {
            background-color: #fff;
        }
    }
}





.c-menu-button__text {
    color: $color-white;
    font-size: 12px;
    line-height: 1;
    position: relative;
    top: -2px;
    transition: color .3s ease;
    display: none;

    @media only screen and (min-width: 768px) {
        display: inline;
    }

    .c-menu-button:hover & {
        color: $color-green;
    }

    .c-menu-button--close & {
        display: none;
    }
}





html,
body {
    height: auto;
    width: auto;
    overflow: visible;
}

.has-sidebar-open,
.has-dialog-open {
    body {
        overflow: hidden;
        position: relative;
        height: 100%;
        overflow: hidden;
    }
}
