/* ==========================================================================
   #ONBOARD
   ========================================================================== */

.c-onboard {
    display: flex;
    height: 100%;
    flex-direction: column;
}





.c-onboard__panel {
    display: none;
    height: 100%;
    text-align: center;
    min-height: 470px;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
        min-height: 540px;
    }
}


.c-onboard__panel--join {
    .c-onboard--join & {
        display: block;
    }
}


.c-onboard__panel--login {
    .c-onboard--login & {
        display: block;
        padding: 20px;
    }
}





.c-onboard__logo {
    fill: $color-white;
    width: 90%;
    padding-left: 10px;

    .c-onboard__panel--join & {
        padding-top: 0;
        margin: 0 auto 10px;
        max-width: 300px;

        @media only screen and (min-width: 768px) {
            max-width: 470px;
            margin-bottom: 0;
        }
    }

    .c-onboard__panel--login & {
        width: 75%;
        margin-top: 130px;
        margin-left: -30px;
        padding-bottom: 25px;

        @media only screen and (min-width: 768px) {
            width: auto;
            max-width: 530px;
            max-height: 240px;
        }
    }
}





.c-onboard__content {
    color: $color-white;
    font-weight: 400;
    padding: 40px 20px 20px;
    text-shadow: rgba(0, 0, 0, 0.8) 1px 0 12px;

    @media only screen and (min-width: 1200px) {
        padding: 60px 0 20px;
    }
}





.c-onboard__content-title {
    font-family: $heading_font_family;
    color: $color-white;
    font-weight: normal;
    margin: 0 auto 10px;
    letter-spacing: -0.17px;
    text-align: center;
    font-size: 20px;
    line-height: 26px;

    @media only screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 32px;
    }
}





.c-onboard__content-body {
    margin: 0 auto;
    padding: 0 20px;
    color: $color-white;
    letter-spacing: -0.19px;
    text-align: center;
    font-weight: normal;
    max-width: 480px;
    font-size: 15px;
    line-height: 22px;

    @media only screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
    }
}





.c-onboard__cta {
    font-size: 15px;
    font-weight: 400;
    padding: 15px 10px 20px;
    color: $color-white;
    margin: 0;

    @media only screen and (min-width: 1200px) {
        padding-bottom: 40px;
    }
}





.c-onboard__cta-title {
    font-size: 13px;
    display: block;
    color: $color-white;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 0;
    font-weight: 400;

    @media only screen and (min-width: 375px) {
        font-size: 13px;
        padding-right: 0;
        padding-left: 0;
    }

    @media only screen and (min-width: 425px) {
        font-size: 14px;
    }

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 18px;
    }

    strong {
        font-weight: 700;
        color: $color-green;
    }
}





.c-onboard__cta-body {
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 0;
    color: $color-grey--medium;

    @media only screen and (min-width: 768px) {
        font-size: 15px;
    }
}





.c-onboard__benefits {
    border-top: 2px solid #cdff6b;
    margin: 20px auto 0;
    padding: 30px 20px 0;
    list-style: none;
    text-align: left;
    max-width: 340px;

    @media only screen and (min-width: 768px) {
        max-width: 440px;
        padding-right: 20px;
        padding-left: 20px;
    }

    li {
        margin: 0 0 15px;
        padding: 0;
        display: flex;
        align-items: center;

        @media only screen and (min-width: 768px) {
            padding: 0 20px;
        }
    }

    .c-onboard__icon {
        width: 26px;
        height: 26px;
        display: block;
        text-align: center;
        margin-right: 18px;
        fill: #cdff6b;
    }

    span {
        font-family: $heading_font_family;
        font-size: 15px;
        color: $color-white;
        letter-spacing: -0.12px;
        line-height: 26px;

        @media only screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.c-onboard__back {
    font-size: 30px;
    color: $color-white;
    font-family: $heading_font_family;
    letter-spacing: 0;
    text-align: center;
    font-weight: normal;
    margin-top: -40px;
    font-size: 26px;
    line-height: 26px;

    @media only screen and (min-width: 768px) {
        margin-top: -55px;
        font-size: 34px;
        line-height: 38px;
    }
}
