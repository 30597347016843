.c-mofo-promises {
    background-color: $color-white;
    border-bottom: 1px solid #dadada;
    padding: 0 15px;
}


.c-mofo-promises__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    position: relative;
    height: 36px;

    @media only screen and (min-width: 980px) {
        width: 90vw;
    }
}


.c-mofo-promises__group {
    @media only screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 1s ease-in-out;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.c-mofo-promises__group--active {
    @media only screen and (min-width: 768px) {
        opacity: 1;
        position: static;
    }
}


.c-mofo-promise {
    padding: 8px 0;
    color: $color-grey--dark;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 1s ease-in-out;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;

    @media only screen and (min-width: 768px) {
        position: static;
        padding-right: 10px;
        padding-left: 10px;
        opacity: 1;
    }
}

.c-mofo-promises__group--active .c-mofo-promise--active {
    opacity: 1;
    position: static;
}


.c-mofo-promise__icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    opacity: 0.5;

    svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }
}


.c-mofo-promise__name {
    font-size: 12px;
    font-weight: 400;
    padding: 1px 0;
    line-height: 18px;

    strong {
        color: $color-grey--dark;
        font-weight: bold;
    }
}
