/* ==========================================================================
   #MIXINS
   ========================================================================== */
   // scss-lint:disable ImportantRule





// Drop clearfix styles into our element.
// ========================================================================== */

@mixin clearfix() {

    &::after {
        content: '' !important;
        display: block !important;
        clear: both !important;
    }

}





// Visually hidden content
//
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
// ========================================================================== */

@mixin hide-visually($important: false) {

    @if ($important == false) {
        $important: null;
    } @else {
        $important: !important;
    }

    border: 0 $important;
    clip: rect(0 0 0 0) $important;
    height: 1px $important;
    margin: -1px $important;
    overflow: hidden $important;
    padding: 0 $important;
    position: absolute $important;
    width: 1px $important;
}





// Kellum
//
// The Kellum method for image replacement is beautifully simple:
// zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
// ========================================================================== */

@mixin kellum() {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}
