/* ==========================================================================
   #DISCOUNT
   ========================================================================== */

.c-discount {
    position: absolute;
    top: -15px;
    left: 0;
    color: $color-white;
    border-radius: 50%;
    background-color: $color-red;
    z-index: 110;
    padding: 2px;

    .c-offer--unavailable & {
        background-color: #474747;
    }
}





.c-discount__amount {
    border: 2px solid $color-white;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    strong {
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
        display: block;
        color: $color-white;
    }

    span {
        font-size: 9px;
        font-weight: bold;
    }
}
