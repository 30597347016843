/* ==========================================================================
   #RIBBONS
   ========================================================================== */

.c-ribbon {
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 10px;
    overflow: hidden;
    width: 79px;
    height: 81px;
}





.c-ribbon__text {
    font-size: 8px;
    font-weight: bold;
    position: relative;
    top: 21px;
    left: -6px;
    width: 107px;
    padding: 5px 0 6px;
    transform: rotate(45deg);
    text-align: center;
    text-transform: uppercase;
    color: $color-white;
    background-color: $color-orange;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    text-shadow: rgba(255, 255, 255, .5) 0 1px 0;

    &::before,
    &::after {
        position: absolute;
        bottom: -3px;
        content: '';
        border-top: 3px solid darken($color-orange, 10%);
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}
