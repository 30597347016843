/* ==========================================================================
   #DEFINITIONS
   ========================================================================== */


/* Colors
   ====================================================================== */

// Shade mix colour with black
@function shade($color, $percentage) {
    @return mix($color, #000, $percentage);
}

// Shade mix colour with white
@function tint($color, $percentage) {
    @return mix($color, #fff, $percentage);
}

$heading_font_family: 'GT Walsheim', sans-serif;
$sans_font_family: 'Graphik', sans-serif;

@font-face {
    font-family: 'GT Walsheim';
    src: font-url('GT-Walsheim-Bold.woff2') format('woff2'),
         font-url('GT-Walsheim-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim';
    src: font-url('GT-Walsheim-Bold.woff2') format('woff2'),
         font-url('GT-Walsheim-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim';
    src: font-url('GT-Walsheim-Medium.woff2') format('woff2'),
         font-url('GT-Walsheim-Medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: font-url('Graphik-Regular-Web.woff2') format('woff2'),
         font-url('Graphik-Regular-Web.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: font-url('Graphik-Medium-Web.woff2') format('woff2'),
         font-url('Graphik-Medium-Web.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

$color-white: #fff !default;
$color-cream: #fafaf7;
$color-grey--dark: #6c6c6c;
$color-grey--medium: #dadada;
$color-grey--light: #f0f0f0;

$color-black:     #000 !default;
$color-black--80: tint($color-black, 80%) !default;
$color-black--60: tint($color-black, 60%) !default;
$color-black--40: tint($color-black, 40%) !default;
$color-black--20: tint($color-black, 20%) !default;

$color-green:     #77b204 !default;
$color-green--80: tint($color-green, 80%) !default;
$color-green--60: tint($color-green, 60%) !default;
$color-green--40: tint($color-green, 40%) !default;
$color-green--20: tint($color-green, 20%) !default;

$color-blue:     #1e9ad8 !default;
$color-blue--80: tint($color-blue, 80%) !default;
$color-blue--60: tint($color-blue, 60%) !default;
$color-blue--40: tint($color-blue, 40%) !default;
$color-blue--20: tint($color-blue, 20%) !default;

$color-orange:     #fda705 !default;
$color-orange--80: tint($color-orange, 80%) !default;
$color-orange--60: tint($color-orange, 60%) !default;
$color-orange--40: tint($color-orange, 40%) !default;
$color-orange--20: tint($color-orange, 20%) !default;

$color-red:     #d51c5c !default;
$color-red--80: tint($color-red, 80%) !default;
$color-red--60: tint($color-red, 60%) !default;
$color-red--40: tint($color-red, 40%) !default;
$color-red--20: tint($color-red, 20%) !default;

$color-mofoGreen: #77b204;
$color-greenLantern: #48a90f;
$color-vader: #131314;
$color-darkMofo: #232627;
$color-redRedWine: #9a0945;
$color-liquidGold: #d6a871;
$color-dorianGray: #626468;
$color-almostWhite: #f7f8fa;
$color-neonGreen: #cdff6b;
$color-bubbly: #fff2d4;
$color-charlie: #fca403;
$color-clockwork: #ff6d21;
$color-sunlit: #fca403;
$color-deal: #d51c5c;
$color-pinky: #ff62a5;
$color-mystique: #1392c5;
$color-papaSmurf: #1cb6f4;
$color-smurfette: #e0f6ff;
