/* ==========================================================================
   #ACCORDION-ITEM
   ========================================================================== */

.c-accordion-item {
    margin-bottom: 20px;
    border: 1px solid $color-grey--medium;
    border-radius: 5px;
    background-color: $color-white;
    overflow: hidden;
}


.c-accordion-item--is-open {
    border-color: $color-green;
}





.c-accordion-item__header {
    padding: 15px;
    outline: 0;
    display: block;
    font-weight: bold;
    width: 100%;
    text-align: left;
    background-color: $color-white;
    border: 0;
    color: #777;

    .c-accordion-item--is-open & {
        color: #111;
        border-bottom: 1px solid $color-grey--medium;
    }

    .c-accordion-item:first-child & {
        border-bottom-width: 0;
    }

    &:hover {
        color: $color-green;
    }
}






.c-accordion-item__title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 39px;

    &::before {
        content: '';
        font-family: 'Ionicons';
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        width: 24px;
        margin-right: 15px;
        border: solid 1px rgba(0, 0, 0, .145);
        background-color: rgba(0, 0, 0, .02);
        border-radius: 100%;
        text-align: center;
        color: $color-white;
        font-size: 12px;
        line-height: 24px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-left: -39px;

        .c-accordion-item--is-open & {
            content: '\f122';
            background-color: $color-green;
            border-color: $color-green;
        }
    }
}





.c-accordion-item__panel {
    display: none;
    position: relative;

    .c-accordion-item--is-open & {
        display: block;

        @media only screen and (min-width: 1200px) {
            display: flex;
            justify-content: center;
        }
    }
}





.c-accordion-item__panel-content {
    padding: 20px 0;
    margin-right: 50px;
    margin-left: 50px;

    @media only screen and (min-width: 1200px) {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        padding: 15px 30px;
        margin: 0;
    }

    &:first-child {
        padding-bottom: 0;

        @media only screen and (min-width: 1200px) {
            padding-left: 50px;
            padding-bottom: 15px;
            border-right: 1px solid $color-grey--medium;
        }
    }

    .c-btn--facebook {
        padding-right: 5px;
        padding-left: 5px;
    }
}
