/* ==========================================================================
   #HOMEPAGE BANNERS
   ========================================================================== */

.c-banners {
    height: auto;
    padding: 15px 0;
}





.c-banner + .c-banner {
    margin-top: 15px;
}





.c-banner__inner {
    position: relative;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
        max-width: 750px;
    }

    @media only screen and (min-width: 992px) {
        max-width: 970px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1170px;
    }
}





.c-banner__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
