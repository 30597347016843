/* ==========================================================================
   #PAGE-HEADER
   ========================================================================== */

.c-page-header {
    padding: 25px 15px 15px;
    background-color: $color-white;
    margin-bottom: 0;

    @media only screen and (min-width: 768px) {
        padding: 60px 0 20px 0;
    }
}

.c-search-message {
    padding: 25px;
    background-color: #f7f8fa;
    margin-bottom: 25px;

    @media only screen and (min-width: 768px) {
        padding: 50px 0;
        margin-bottom: 50px;
    }
}


.c-page-header__inner {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
}



.c-search-message__inner {
    max-width: 70ch;
    margin: 0 auto;
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;

    p:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }
}




.c-page-header__title {
    font-family: $heading_font_family;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.2;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding-bottom: 0;
    text-align: center;
    letter-spacing: normal;
    color: $color-black;

    @media only screen and (min-width: 768px) {
        font-size: 40px;
        margin: 0 0 20px;
    }
}
