.c-push-banner {
    background: none;
    border: 0;
    outline: 0;
    min-height: 100px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin: 0 auto 20px;
    padding: 0 30px;
    max-width: 750px;
    min-height: 43px;

    @media only screen and (min-width: 992px) {
        max-width: 970px;
        margin-bottom: 40px;
        padding: 0 15px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1170px;
    }
}

.c-push-banner__inner {
    background-color: $color-papaSmurf;
    border-radius: 5px;
    color: $color-white;
    padding: 20px 190px 20px 30px;
    position: relative;
    flex-basis: 100%;
    flex-grow: 1;
    text-align: left;

    .c-push-banner--alternative & {
        background-color: $color-charlie;
    }

    &::after {
        content: "";
        background-image: image-url('site/eddie-wine-glass.png');
        background-size: cover;
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 136px;
        height: 115px;

        @media only screen and (min-width: 992px) {
            right: 40px;
        }
    }
}

.c-push-banner__title {
    font-family: $heading_font_family;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 1.4;
    margin: 0;
    color: $color-white;
    position: relative;

    @media only screen and (min-width: 992px) {
        font-size: 20px;
    }
}

.c-push-banner__text {
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 1.4;
    position: relative;

    strong {
        color: inherit;
        font-weight: bold;
    }

    @media only screen and (min-width: 992px) {
        font-size: 16px;
    }
}
