@mixin progress-item-outer($color, $number) {

    &.c-loyalty-progress__item-outer {
        z-index: 1;
    }

    &.c-loyalty-progress__item-outer--active {
        padding: 1px;
        background: white;
        border: 5px solid lighten($color, 20%);

        @media only screen and (max-width: 768px) {
            border-width: 3px;
        }
    }

    .c-loyalty-progress__item {
        min-width: 42px;
        height: 42px;
        border-radius: 100%;
        border: 1px solid white;
        background-color: $color;

        @media only screen and (max-width: 768px) {
            min-width: 35px;
            height: 35px;
        }

        &.c-loyalty-progress__item--large {
            background: linear-gradient(124.19deg, lighten($color, 15%) 21.1%, $color 58.23%);
            border: 1px solid $color;
            width: 78px;
            height: 78px;

            @media only screen and (max-width: 768px) {
                width: 48px;
                height: 48px;
            }
        }

        &.c-loyalty-progress__item--divider {
            &:after {
                content: '';
                background-image: image_url('site/account/status/icon-dot.svg');
                position: absolute;
                width: 25%;
                height: 3px;
                top: 39px;
                z-index: -1;

                @media only screen and (max-width: 768px) {
                    top: 24px;
                }
            }
        }

        &.c-loyalty-progress__item--active {
            background: linear-gradient(124.19deg, lighten($color, 15%) 21.1%, $color 58.23%);
            border: 1px solid $color;

            &:before {
                font-family: $heading_font_family;
                content: $number;
                position: absolute;
                color: white;
                line-height: 40px;
                font-size: 26px;
                width: 41px;
                font-weight: bold;
                text-align: center;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);

                @media only screen and (max-width: 768px) {
                    line-height: 33px;
                    font-size: 24px;
                    width: 33px;
                }
            }
        }

        &.c-loyalty-progress__item--unlocked {
            background-image: image_url('site/account/status/icon-check.svg');
            background-repeat: no-repeat;
            background-position: center center;
        }

        &.c-loyalty-progress__item--disabled {
            background-color: transparentize($color, 0.7);
        }
    }
}

.c-loyalty-progress {
    display: flex;
    justify-content: space-between;
    margin: 40px auto;
    width: 100%;
    max-width: 460px;
    position: relative;

    @media only screen and (max-width: 768px) {
        padding: 0 20px;
    }

    &--large {
        max-width: 100%;

        @media only screen and (max-width: 768px) {
            margin: 20px auto;
        }

        & .c-loyalty-progress__item-outer {
            border: none;
        }
    }

    &__item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;
    }

    &__divider {
        width: 100%;

        &--unlocked {
            background-image: image_url('site/account/status/icon-line.svg');
            background-repeat: repeat-x;
            background-position: center;
        }

        &--locked {
            background-image: image_url('site/account/status/icon-dot.svg');
            background-repeat: repeat-x;
            background-position: center;
        }
    }

    &__item-heading {
        font-family: $heading_font_family;
        font-size: 24px;
        color: #131314;
        letter-spacing: -0.6px;
        text-align: center;
        line-height: 28px;
        margin-top: 15px;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
            letter-spacing: -0.35px;
        }
    }

    &__item-desc {
        font-family: $sans_font_family;
        font-size: 16px;
        color: #777777;
        letter-spacing: -0.33px;
        text-align: center;
        line-height: 24px;
        margin: 0 -50px;
        max-width: 200px;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    &__item-outer {
        border: 5px solid white;
        border-radius: 100%;
        height: fit-content;

        &--unlocked {
            border: 4px solid #e1e1e1;

            @media only screen and (max-width: 768px) {
                border-width: 3px;
            }
        }

        &--active {
            transform: scale(1.3);
        }

        &--level-1 {
            @include progress-item-outer(#cd7f32, '1');
        }

        &--level-2 {
            @include progress-item-outer(#9babb8, '2');
        }

        &--level-3 {
            @include progress-item-outer(#e7b000, '3');
        }

        &--level-4 {
            @include progress-item-outer(#dedfa5, '4');
        }

        &--level-5 {
            @include progress-item-outer($color-vader, '5');
        }
    }
}
