.c-mofo-rewards {
    background-color: #f8f9fb;
    padding: 40px;

    @media only screen and (max-width: 768px) {
        padding: 35px 0 45px;
    }

    > * {
        padding-bottom: 40px;

        @media only screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
    }

    &__container {
        max-width: 1050px;
        margin: auto;

        @media only screen and (max-width: 768px) {
            padding: 10px 20px;
        }
    }

    &__heading {
        font-family: $heading_font_family;
        font-size: 48px;
        color: #131314;
        letter-spacing: -1.2px;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;

        @media only screen and (max-width: 768px) {
            font-size: 32px;
            letter-spacing: -0.8px;
            line-height: 36px;
            margin-top: 0;
        }
    }

    &__rewards-table {
        table tr th:first-child,
        table tr td:first-child {
            border-left: 1px solid #e4e5e7;
        }

        /* top-left border-radius */
        table tr:first-child th:first-child {
            border-top-left-radius: 4px;
        }

        /* top-right border-radius */
        table tr:first-child th:last-child {
            border-top-right-radius: 4px;
        }

        /* bottom-left border-radius */
        table tr:last-child td:first-child {
            border-bottom-left-radius: 4px;
        }

        /* bottom-right border-radius */
        table tr:last-child td:last-child {
            border-bottom-right-radius: 4px;
        }

        table tr th {
            border-top: 1px solid #e4e5e7;
            text-align: left;
        }

        @media only screen and (max-width: 768px) {
            overflow-x: scroll;
            padding: 0 20px 20px;
        }
    }

    &__subheading {
        font-family: $sans_font_family;
        font-size: 20px;
        color: #232627;
        letter-spacing: -0.5px;
        text-align: center;
        line-height: 30px;
        margin-bottom: 22px;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            letter-spacing: -0.4px;
            line-height: 24px;
            margin-bottom: 0;
        }

        &--green {
            color: $color-mofoGreen;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 24px;

            @media only screen and (max-width: 768px) {
                font-size: 14px;
                letter-spacing: -0.35px;
                line-height: 20px;
                margin-bottom: 22px;
            }
        }

        p {
            max-width: 820px;
            margin: auto;

            @media only screen and (max-width: 768px) {
                max-width: 295px;
            }
        }
    }

    &__extra {
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
}
