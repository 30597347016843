.c-cart-header {
    padding: 20px 0;

    @media only screen and (min-width: 992px) {
        padding: 40px 0;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
    }
}

.c-cart-header__title {
    color: #131314;
    font-weight: normal;
    font-family: "GT Walsheim", sans-serif;
    margin: 0;
    font-size: 24px;
    line-height: 1.167;
    letter-spacing: -0.6px;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) {
        font-size: 36px;
        line-height: 1.111;
        letter-spacing: -0.9px;
        margin-right: 40px;
        margin-bottom: 0;
    }
}

.c-cart-header__content {
    @media only screen and (min-width: 992px) {
        display: flex;
    }
}

.c-cart-header__item {
    display: flex;

    &:first-child {
        margin-bottom: 16px;

        @media only screen and (min-width: 992px) {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    svg {
        margin-right: 16px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    span {
        color: #626468;
        font-family: "Graphik", sans-serif;
        margin: 0;
        font-size: 14px;
        line-height: 1.42;
        letter-spacing: -0.35px;

        @media only screen and (min-width: 992px) {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.4px;
        }
    }
}

.c-cart-header__extra {
    width: 100%;
    color: #626468;
    font-family: "Graphik", sans-serif;
    margin: 20px 0 0;
    padding: 20px 0 0;
    font-size: 14px;
    line-height: 1.42;
    letter-spacing: -0.35px;
    border-top: 1px solid #dadada;
    display: flex;
    align-items: flex-start;

    svg {
        margin-top: 4px;
        margin-right: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        width: 24px;
        height: auto;
    }

    p {
        max-width: 75ch;
    }

    @media only screen and (min-width: 992px) {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.4px;
    }
}