$default-cell-padding: 17px;

@mixin tier-heading($color) {
    background-color: transparentize($color, 0.94);

    .c-rewards-table__head-icon {
        background-color: $color;
    }
}

.c-rewards-table {
    font-family: $heading_font_family;
    margin: auto;
    border-spacing: -1px;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    max-width: 1140px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    border-collapse: separate;

    td {
        overflow: hidden;
    }

    &__cell {
        border-right: 1px solid #e4e5e7;
        border-bottom: 1px solid #e4e5e7;
        padding: $default-cell-padding;
        min-width: 185px;
        overflow: hidden;

        @media only screen and (max-width: 768px) {
            height: 77px;
        }
    }

    &__reward-desc-head {
        padding: $default-cell-padding;
        font-size: 18px;
        color: white;
        background: #368807;
        border: 1px solid #3c8315 !important;
        text-align: left;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
            letter-spacing: -0.35px;
            line-height: 18px;
            min-width: 134px;
            padding: 20px;
        }
    }

    &__reward-desc {
        font-weight: 100;
        font-size: 14px;
        color: white;
        border-left: 1px solid #3c9a07 !important;
        border-right: 1px solid #3c9a07;
        border-bottom: 1px solid #3c9a07;
        background: $color-greenLantern;
        text-align: left;

        @media only screen and (max-width: 768px) {
            font-size: 12px;
            letter-spacing: -0.25px;
            line-height: 18px;
            min-width: 134px;
            padding: 20px;
        }
    }

    &__heading-row {
        background-color: white;
    }

    &__free {
        font-family: $heading_font_family;
        font-size: 20px;
        color: $color-mofoGreen;
        letter-spacing: -0.5px;
        text-align: center;
        line-height: 28px;
        font-weight: bold;
    }

    &__cell-available {
        background-color: white;
        text-align: center;
        color: black;
    }

    &__cell-unavailable {
        background-color: #f7f8fa;
        text-align: center;
    }

    &__checked {
        margin: auto;
        background-image: image_url('site/account/status/icon-check.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $color-mofoGreen;
        width: 25px;
        height: 25px;
        border-radius: 25px;
    }

    &__year {
        font-weight: 100;
        margin-left: 5px;
    }

    &__head-title {
        color: $color-vader;
    }


    &__head-points {
        font-family: $sans_font_family;
        font-size: 11px;
        color: #232627;
        letter-spacing: -0.3px;
        line-height: 18px;
    }

    &__head-container {
        display: flex;
        align-items: center;
    }

    &__head-icon {
        min-width: 40px;
        height: 40px;
        border-radius: 30px;
        margin-right: 10px;
    }

    &__head--level-1 {
        background-color: #fbf4ed;

        .c-rewards-table__head-icon {
            background-color: #cd7f32;
        }
    }

    &__head--level-2 {
        background-color: #f7f7f8;

        .c-rewards-table__head-icon {
            background-color: #9babb8;
        }
    }

    &__head--level-3 {
        background-color: #fdf8e8;

        .c-rewards-table__head-icon {
            background-color: #e7b000;
        }
    }

    &__head--level-4 {
        background-color: #fcfbf8;

        .c-rewards-table__head-icon {
            background-color: #dedfa5;
        }
    }

    &__head--level-5 {
        background-color: #f5f5f5;

        .c-rewards-table__head-icon {
            background-color: $color-vader;
        }
    }
}
