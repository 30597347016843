/* ==========================================================================
   #HEADER-SEARCH
   ========================================================================== */

.c-header-search {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: lighten($color-black, 15%);
    background-color: rgba($color-cream, .97);
    justify-content: center;
    flex-direction: column;
    display: flex;
    visibility: hidden;
    opacity: 1;
    transition: opacity .4s ease-in-out;
    z-index: -1;
    display: none;

    @media only screen and (min-width: 768px) {
        border: 30px solid lighten($color-black, 15%);
    }

    @media only screen and (min-width: 1200px) {
        border-width: 50px;
    }
}

.c-header-search--is-visible {
    display: flex;
    position: fixed;
    opacity: 1;
    visibility: visible;
    z-index: 1500;
}





.c-header-search__form {
    max-width: none;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 1550;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
        max-width: 720px;
        padding: 0 20px;
    }

    @media only screen and (min-width: 992px) {
        max-width: 940px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1140px;
        padding: 0;
    }
}





.c-header-search__label {
    z-index: 40;
    color: lighten($color-black, 50%);
    font-size: 18px;
    transition: opacity .3s ease;
    padding-bottom: 60px;
    display: block;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        font-size: 28px;
        left: 0;
    }

    @media only screen and (min-width: 1200px) {
        width: 60%;
    }
}





.c-header-search__term {
    font-size: 30px;
    position: relative;
    z-index: 50;
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    padding: 15px 0;
    vertical-align: top;
    border: 0;
    border-bottom: 2px dashed lighten($color-black, 15%);
    outline: 0;
    background-color: transparent;
    font-weight: 400;

    @media only screen and (min-width: 768px) {
        font-size: 45px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 60px;
    }

    &::placeholder {
        color: rgba($color-black, .10);
    }
}





.c-header-search__submit {
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    margin-left: auto;

    span {
        display: none;

        @media only screen and (min-width: 768px) {
            display: inline;
        }
    }
}





.c-header-search__close {
    top: 20px;
    right: 20px;
    position: absolute;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 30px;
    transition: color .3s ease;
    z-index: 1550;

    @media only screen and (min-width: 768px) {
        font-size: 36px;
    }

    &.focus-visible,
    &:hover {
        color: $color-black;
    }
}
