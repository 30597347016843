.c-promo-bar {
    background-color: #000;
    color: #999;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    border-bottom: 1px solid #333;
}

.c-promo-bar__inner {
    display: flex;
    width: 100%;
}

.c-promo-bar__left {
    flex: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 6px 15px;
    border-right: 1px solid #333;
}

.c-promo-bar__center {
    flex: 1;
    padding: 0 15px;
}

.c-promo-bar__right {
    flex: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 6px 15px;
    margin-left: auto;
    border-left: 1px solid #333;
}

.c-promo-bar__contact-us {
    display: none;

    a {
        color: #777;

        &:hover {
            color: $color-green;
        }
    }

    @media only screen and (min-width: 768px) {
        display: block;
    }
}

.c-promo-bar__social-proof {
    overflow: hidden;
}

.c-promo-bar__social-proof-text {
    color: #777;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 0;
    height: 29px;

    a {
        color: #777;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px dotted #383838;

        &:hover {
            color: $color-green;
            border-bottom: 1px solid $color-green;
        }
    }
}

.c-promo-bar__account-links {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
    }
}

.c-promo-bar__profile-link-greeting {
    display: inline-block;
    margin: 0;
}

.c-promo-bar__profile-link-separator {
    margin: 0 5px;
}

.c-promo-bar__auth-links {
    text-transform: uppercase;
}

.c-promo-bar__auth-link-separator {
    text-transform: none;
    margin: 0 3px;
}
