.next-tier-progress {
    padding: 0 10px;

    @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
    }

    &__bar-outer {
        margin-bottom: 10px;
        display: flex;
        padding: 0 2px;
        align-items: center;
        height: 8px;
        border-radius: 4px;
        background-color: $color-greenLantern;
        width: 100%;
    }

    &__bar-inner {
        background-color: $color-neonGreen;
        border-radius: 4px;
        height: 4px;
    }

    &__description {
        font-family: $sans_font_family;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.291667px;
        color: #777777;
    }

    &__description-value {
        font-weight: bold;
        color: $color-greenLantern;
    }

    &__description-tier {
        font-weight: bold;
        color: black;
    }
}
