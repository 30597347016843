.c-cart-summary__title {
    border-bottom: 1px solid #e2e2e2;
    padding: 20px;
    color: $color-vader;
    font-weight: normal;
    font-family: $heading_font_family;
    margin: 0;
    font-size: 18px;
    line-height: 1.333;
    letter-spacing: -0.45px;
    
    @media only screen and (min-width: 992px) {
        font-size: 24px;
        line-height: 1.167;
        letter-spacing: -0.6px;
        padding: 30px;
    }
}

.c-cart-summary__line-items {
    padding: 10px 0;

    @media only screen and (min-width: 992px) {
        padding: 15px 0;
    }
}

.c-cart-summary__line-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    padding: 10px 20px;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        padding: 10px 30px;
    }
}

.c-cart-summary__label {
    margin-right: 16px;
    color: $color-darkMofo;
}

.c-cart-summary__remove-item {
    display: inline-block;

    button {
        flex-grow: 0;
        flex-shrink: 0;
        background: none;
        padding: 0;
        border: 0;
        outline: 0;
        appearance: none;
        color: $color-deal;
        text-decoration: underline;
        font-size: 12px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.c-cart-summary__price {
    font-weight: bold;
    color: $color-darkMofo;

    span {
        text-transform: uppercase;
        color: $color-greenLantern;
    }
}

.c-cart-summary__note {
    flex-basis: 100%;
    font-size: 12px;
    letter-spacing: -0.17px;
    line-height: 18px;
}

.c-cart-summary__note--highlighted {
    color: $color-deal;
}

.c-cart-summary__code-form {
    padding: 20px;
    border-top: 1px solid #e2e2e2;

    @media only screen and (min-width: 992px) {
        padding: 20px 30px;
    }

    form {
        display: none;
    }

    input {
        font-size: inherit;
        font-family: inherit;
        color: $color-dorianGray;
        width: 100%;
        box-sizing: border-box;
        background-color: #f5f7fa;
        border: 1px solid #c9ccd3;
        border-bottom-width: 1px;
        border-radius: 2px;
        padding: 13px 16px 12px;
        outline: 0;
        appearance: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            border-color: #a3a7af;
        }

        &:focus {
            background-color: $color-white;
        }
    }
}

.c-cart-summary__code-form--is-open form {
    margin-top: 15px;
    display: flex;
}

.c-cart-summary__code-form-toggle {
    flex-grow: 0;
    flex-shrink: 0;
    background: none;
    padding: 0;
    border: 0;
    outline: 0;
    appearance: none;
    font-size: 14px;
    color: $color-greenLantern;
    font-weight: bold;
    text-decoration: underline;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        text-decoration: none;
    }

    .c-cart-summary__code-form--is-open & {
        svg {
            transform: rotate(180deg);
        }
    }
}

.c-cart-summary__code-form-button {
    flex-grow: 0;
    flex-shrink: 0;
    background: none;
    padding: 0 20px;
    border: 0;
    outline: 0;
    margin-left: 16px;
    appearance: none;
    font-weight: bold;
    font-size: 14px;
    color: $color-white;
    background-color: $color-greenLantern;
    border-radius: 100px;

    &:hover {
        background-color: tint($color-greenLantern, 95%);
    }
}

.c-cart-summary__savings {
    font-family: $sans_font_family;
    background-color: $color-smurfette;
    color: #131314;
    font-size: 14px;
    letter-spacing: -0.35px;
    line-height: 1.42;
    padding: 15px 20px 15px 15px;
    display: flex;
    border: 1px solid $color-white;
    border-top: 0;

    @media only screen and (min-width: 992px) {
        padding: 15px 30px 15px 20px;
    }

    svg {
        margin-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    strong {
        color: $color-mystique;
        font-weight: bold;
    }
}

.c-cart-summary__total {
    font-family: $heading_font_family;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    background-color: $color-almostWhite;
    border: 1px solid $color-white;
    padding: 10px 20px;
    font-size: 16px;
    color: $color-vader;
    font-weight: bold;

    @media only screen and (min-width: 992px) {
        padding: 16px 30px;
        font-size: 20px;
    }

    > strong {
        color: $color-greenLantern;
    }
}

.c-cart-summary__footer {
    padding: 15px 10px;

    @media only screen and (min-width: 992px) {
        padding: 20px 20px;
    }
}
