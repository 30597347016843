/* ==========================================================================
   #BUTTONS
   ========================================================================== */

%c-btn {
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    min-width: 100px;
    margin: 0;
    padding: 12px 3.5em;
    cursor: pointer;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    color: #555;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    outline: 0;
    background-color: #eaeaea;
    touch-action: manipulation;
    transition: all .1s;

    &:focus,
    &:hover {
        text-decoration: none;
        color: #555;
        border-color: #e7e7e7;
        background-color: #efefef;
    }

    &.c-pagination__more {
        width: 190px;
    }
}

.c-btn {
    @extend %c-btn;
}

.c-btn--loading {
    opacity: .5;
    border-color: #e2e2e2;
    background-color: #eaeaea;
    color: #555;
}





/* Style variants
   ========================================================================== */

.c-btn--full {
    width: 100%;
}

.c-btn--ghost {
    // color: #555;
    // border-color: darken($color-btn-primary-bg, 1%);
    background-color: $color-white;

    &:focus,
    &:hover {
        color: $color-white;
        border-color: #555;
        background-color: #555;
    }

    &:visited {
        color: #555;
        border-color: #e2e2e2;
        background-color: $color-white;
    }
}

%c-btn--primary {
    color: $color-btn-primary;
    border-color: darken($color-btn-primary-bg, 1%);
    background-color: $color-btn-primary-bg;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 50px;

    &:focus,
    &:hover {
        color: $color-btn-primary;
        border-color: $color-btn-primary-bg;
        background-color: $color-btn-primary-bg-hover;
    }
}

.c-btn--primary {
    @extend  %c-btn--primary;
}



%c-btn--secondary {
    color: $color-btn-secondary;
    border-color: darken($color-btn-secondary-bg, 1%);
    background-color: $color-btn-secondary-bg;

    &:focus,
    &:hover {
        color: $color-btn-secondary;
        border-color: $color-btn-secondary-bg-hover;
        background-color: $color-btn-secondary-bg-hover;
    }
}

.c-btn--secondary {
    @extend %c-btn--secondary;
}


%c-btn--tertiary {
    color: $color-btn-tertiary;
    border-color: darken($color-btn-tertiary-bg, 1%);
    background-color: $color-btn-tertiary-bg;

    &:focus,
    &:hover {
        color: $color-btn-tertiary;
        border-color: $color-btn-tertiary-bg-hover;
        background-color: $color-btn-tertiary-bg-hover;
    }
}

.c-btn--tertiary {
    @extend %c-btn--tertiary;
}


.c-btn--facebook {
    color: $color-btn-facebook;
    border-color: darken($color-btn-facebook-bg, 1%);
    background-color: $color-btn-facebook-bg;
    border-radius: 50px;
    max-width: 300px;
    margin: 0 auto;
    display: block;

    strong {
        font-weight: bold;
        color: #fff;
    }

    &:focus,
    &:hover {
        color: $color-btn-facebook;
        border-color: $color-btn-facebook-bg-hover;
        background-color: $color-btn-facebook-bg-hover;
    }
}

.c-btn--google {
    color: $color-btn-google;
    border-color: #dadce0;
    background-color: $color-btn-google-bg;
    border-radius: 50px;
    max-width: 300px;
    margin: 10px auto 0;
    display: block;
    font-weight: 500;
    font-family: "Google Sans",arial,sans-serif;
    &:focus,
    &:hover {
        border-color: #d2e3fc;
        background-color: #f8fafe;
    }
    &:before {
      background-image: image-url('site/google.svg');
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: center center;
      width: 18px;
      height: 18px;
      content: ' ';
      display: inline-block;
      vertical-align: top;
      margin-left: -40px;
      margin-right: 30px;
    }
}


.c-btn--danger {
    color: $color-btn-danger;
    border-color: darken($color-btn-danger-bg, 1%);
    background-color: $color-btn-danger-bg;

    &:focus,
    &:hover {
        color: $color-btn-danger;
        border-color: $color-btn-danger-bg-hover;
        background-color: $color-btn-danger-bg-hover;
    }
}





.c-btn:disabled,
.c-btn[disabled],
.c-btn--disabled {
    opacity: .3;
}
