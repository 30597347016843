/* ==========================================================================
   #OFFER-BUTTONS
   ========================================================================== */

.c-offer-btn {
    @extend  %c-btn;
    font-family: $heading_font_family;
    width: 100%;
    border-radius: 50px;
    padding: 12px 10px;
    font-weight: 300;

    .c-offer--available & {
        @extend  %c-btn--primary;
        background-color: #48a90f;
        border-color: #48a90f;
    }

    .c-offer--almost-gone &,
    .c-offer--almost_gone & {
        @extend  %c-btn--secondary;
    }

    .c-offer--selling-fast &,
    .c-offer--selling_fast & {
        @extend  %c-btn--tertiary;
    }

    .c-offer--unavailable & {
        @extend  %c-btn--primary;
    }
}
