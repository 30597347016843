/* ==========================================================================
   #AUTH
   ========================================================================== */

.c-auth__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: $color-black;
    background-repeat: no-repeat;
    background-image: image-url('site/bg__auth.jpg');
    background-size: 250%;
    background-position: 20% 0;

    @media only screen and (min-width: 768px) {
        background-size: 150%;
        background-position: 0 0;
        position: relative;
    }

    @media only screen and (min-width: 1024px) {
        background-position: 50% 50%;
        background-size: cover;
    }
}





.c-auth {
    display: flex;
    align-self: baseline;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    position: relative;

    @media only screen and (min-width: 768px) {
        width: 80vw;
    }

    @media only screen and (min-width: 1024px) {
        flex-direction: row;
        align-self: center;
    }
}





.c-auth__aside {
    flex: 1;
}





.c-auth__content {
    align-self: center;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    background: $color-white;
    width: 100%;

    @media only screen and (min-width: 768px) {
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.10);
    }
}





.c-auth__options {
    background-color: $color-white;
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
        padding: 0 40px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media only screen and (min-width: 1024px) {
        padding: 0 20px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 0 40px;
    }
}





.c-auth__divider {
    text-transform: uppercase;
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    padding-top: 12px;
    margin-bottom: 15px;
    overflow: hidden;
    text-align: center;
}


.c-auth__logo {
    text-align: center;
    padding: 30px 20px 25px;
}
