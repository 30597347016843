/* ==========================================================================
   #USER-PROFILE
   ========================================================================== */

.c-user-profile {
    padding: 12px 0;
    text-align: center;
}

.c-user-profile__image {
    width: 66px;
    margin: 0 auto 10px;
    display: block;

    img {
        border-radius: 50%;
        max-width: 100%;
        vertical-align: middle;
    }
}

.c-user-profile__greeting {
    font-size: 14px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
}

.c-user-profile__motto {
    color: #777;
    font-size: 11px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 0;
}
