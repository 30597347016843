/* ==========================================================================
   #PAGE-CONTENT
   ========================================================================== */

.c-page-content {
    height: 100%;
    background: $color-white;
    position: relative;

    .cart & {
        background-color: #eaecef;
    }
}
