.c-related-pages {
    padding: 25px 15px 50px;
    background-color: #f7f7f7;
    margin-bottom: 0;

    @media only screen and (min-width: 768px) {
        padding: 60px 0 100px;
    }
}

.c-related-pages__inner {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
}


.c-related-pages__title {
    font-family: $heading_font_family;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.2;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding-bottom: 0;
    text-align: center;
    letter-spacing: normal;
    color: $color-black;

    @media only screen and (min-width: 768px) {
        font-size: 40px;
        margin: 0 0 20px;
    }
}

.c-related-links {
    display: flex;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.c-related-links__link {
    padding: 0 5px;
    
    a {
        display: block;
        padding: 5px;
    }
}
