/* ==========================================================================
   #FORM
   ========================================================================== */

.c-form {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
}





.c-form__buttons {
    padding: 5px 0 0;
    margin-top: auto;

    .c-auth & {
        text-align: center;
    }
}





.c-form__forgot-password {
    display: inline-block;
    color: $color-grey--dark;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    outline: 0;

    &:focus,
    &:hover {
        color: $color-black;
        text-decoration: underline;
    }
}





.c-form__input-reminder {
    form.standard-form & input.standard-form-control {
        border: 1px solid $color-orange;
    }

    .standard-form-hint {
        color: $color-orange;
    }
}
