/* ==========================================================================
   #SKIP-MAIN
   ========================================================================== */

.c-skip-link {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    transition: color .3s ease, background-color .3s ease;
    background-color: $color-white;
    color: $color-black;

    &:active,
    &.focus-visible {
        color: $color-black;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
        overflow: auto;
        padding: .5em 1em;
        text-align: center;
        font-size: 1.2em;
        z-index: 999;
        font-weight: bold;
        text-decoration: none;
    }

    &:hover {
        background-color: $color-green;
        color: $color-white;
    }

    @media print {
        display: none;
    }
}
