.c-service-message {
    padding: 15px;
    width: 100%;
    text-align: left;
    background-color: #fff8eb;
    position: relative;
    border-bottom: 5px solid #ffc65c;

    @media only screen and (min-width: 768px) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.c-service-message--is-dismissed {
    opacity: 0;
    z-index: -1;
    display: none;
}


.c-service-message__text {
    margin: 0;
    padding-left: 0;
    padding-right: 30px;
    color: #333;
    font-weight: bold;

    span {
        margin-right: 5px;
        display: inline-block;
    }

    a {
        text-decoration: none;
        color: #333;
        border-radius: 100px;
        border: 1px solid $color-grey--dark;
        outline: 0;
        padding: 0.5em 1em;
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
        margin: 5px 0;

        &:hover {
            background-color: #333;
            border-color: #333;
            color: $color-white;
        }
    }
}


.c-service-message__close {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -8px 0 0;
    padding: 0;
    border: 0;
    background: none;
    outline: 0;
    height: 16px;
    width: 12px;
    color: $color-grey--dark;

    svg {
        fill: currentColor;
    }

    &:hover {
        color: #333;
    }
}
