/* ==========================================================================
   #PAGE-MESSAGE
   ========================================================================== */

.c-page-message {
    padding: 40px 0 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    text-align: center;

    @media only screen and (min-width: 980px) {
        width: 90vw;
    }
}
