/* ==========================================================================
   #FILTER-GROUP
   ========================================================================== */

.c-filter-group {
    border-bottom: 1px solid $color-grey--medium;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: none;
    }

    li {
        font-size: 12px;
    }
}

.c-filter-group--open {
    ul {
        border-top: 1px solid $color-grey--light;
        display: block;
        background-color: lighten($color-grey--light, 5%);
    }
}

.c-filter-group--overflow ul {
    max-height: 335px;
    overflow-y: auto;
}





.c-filter-group__label {
    padding: 12px 20px 12px 15px;
    margin: 0;
    color: $color-grey--dark;
    cursor: pointer;
    transition: all .3s ease-out;
    position: relative;

    &:hover {
        background-color: $color-grey--light;
    }

    h4 {
        font-size: 14px;
        margin: 0;
        padding: 0;
        font-weight: 400;
    }

    p {
        font-size: 10px;
        font-style: italic;
        padding-top: 4px;
        margin: 0;
    }

    &::after {
        content: '\f489';
        margin-left: auto;
        margin-right: -5px;
        font-size: 20px;
        width: 20px;
        text-align: center;
        color: $color-grey--dark;
        position: absolute;
        right: 20px;
        top: 7px;
        font-family: 'Ionicons';
    }

    .c-filter-group--open & {
        background-color: lighten($color-grey--light, 2%);

        &::after {
            content: '\f462';
        }

        &:hover {
            background-color: $color-grey--light;
        }
    }
}





.c-filter-group__summary {
    display: none;
    padding: 0 15px 9px;
    margin-top: -6px;

    p {
        margin: 0 10px 0 0;
        flex: 1;
    }

    &--open {
        display: flex;
    }
}





.c-filter-group__clear {
    border-radius: 25px;
    padding: 2px 0;
    font-size: 10px;
    min-width: auto;
    outline: 0;
    border: 1px solid $color-grey--medium;
    color: #333;
    background: $color-white;
    display: inline-block;
    transition: all .1s;
    width: 50px;
    margin-left: auto;
    align-self: flex-start;
    position: relative;
    right: -15px;

    &:focus,
    &:hover {
        text-decoration: none;
        border-color: #333;
        background-color: #333;
        color: $color-white;
    }
}
