/* ==========================================================================
   #FILTER
   ========================================================================== */

.c-filter {
    background: $color-white;
    margin: 0 0 30px;
    font-weight: 400;
    font-size: 12px;
    padding-top: 0;

    @media only screen and (min-width: 768px) {
        position: relative;
        transform: translateY(-32px);
        margin-bottom: 15px;
    }
}





.c-filter__header {
    position: relative;

    @media only screen and (min-width: 768px) {
        margin-bottom: 16px;
    }
}





.c-filter__title {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: #333;
    display: flex;
    align-items: center;
    padding: 11px 35px 11px 15px;
    border: 1px solid $color-grey--medium;

    @media only screen and (min-width: 768px) {
        font-size: 14px;
        font-weight: bold;
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    span {
        font-weight: 400;
        display: inline-block;
        margin-left: 5px;
    }

    &::after {
        content: '\f489';
        margin-right: -5px;
        font-size: 20px;
        width: 20px;
        text-align: center;
        color: #6c6c6c;
        position: absolute;
        right: 20px;
        top: 7px;
        font-family: 'Ionicons';
        cursor: pointer;

        @media only screen and (min-width: 768px) {
            content: '';
        }
    }
}

.c-filter__title--open {
    &::after {
        content: '\f462';

        @media only screen and (min-width: 768px) {
            content: '';
        }
    }
}





.c-filter__controls {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
        border-top: 1px solid $color-grey--medium;
    }

    li {
        padding: 0;
    }

    &--open {
        display: block;
    }
}





.c-filter__clear-all {
    border-radius: 25px;
    padding: 2px 0;
    font-size: 10px;
    min-width: auto;
    outline: 0;
    background-color: transparent;
    position: absolute;
    right: 48px;
    top: 10px;
    color: #333;
    display: none;
    border: 0;

    @media only screen and (min-width: 768px) {
        right: 0;
        top: 0;
        padding: 6px 0 2px;
    }

    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &--open {
        display: inline-block;
    }
}





.c-filter__toolbar {
    width: 100%;
    order: 1;
    padding: 0 15px;
    min-height: 35px;

    @media only screen and (min-width: 768px) {
        order: 2;
        display: flex;
        margin-bottom: 15px;
    }
}





.c-filter__sort {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    display: block;
    position: relative;
    margin-left: auto;

    @media only screen and (min-width: 768px) {
        margin-bottom: 10px;
        display: inline-block;
    }

    &::after {
        content: '\f3d0';
        position: absolute;
        right: 10px;
        top: 12px;
        display: block;
        width: 20px;
        height: 17px;
        font-family: 'Ionicons';
        font-size: 14px;
        color: $color-grey--dark;
        z-index: 5;

        @media only screen and (min-width: 768px) {
            top: 7px;
            right: 0;
        }
    }


    label {
        color: $color-grey--dark;
        margin-right: 5px;
    }

    select {
        appearance: none;
        border: 0;
        cursor: pointer;
        background-color: transparent;
        color: #40354e;
        font-family: inherit;
        padding: 11px 35px 11px 15px;
        background: transparent;
        border-radius: 0;
        border: 0;
        outline: 0;
        position: relative;
        z-index: 10;
        width: 100%;
        border: 1px solid $color-grey--medium;
        margin-bottom: 10px;

        @media only screen and (min-width: 768px) {
            width: auto;
            border: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-bottom: 0;
        }

        &::-ms-expand {
            display: none;
        }
    }
}
