.cta-button {
    background: $color-greenLantern;
    border-radius: 30px;
    color: $color-white;
    text-decoration: none;
    padding: 10px 36px;
    font-weight: bold;
    font-family: $heading_font_family;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: inline-block;
    outline: 0;
    position: relative;
    margin: 0 0 20px;
    white-space: nowrap;
    transition: background-color 0.3s ease-in-out;

    @media (min-width: 768px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        color: $color-white;
        background-color: #43a00e;
    }
}
