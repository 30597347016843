/* ==========================================================================
   #OFFER-PLACEHOLDER
   ========================================================================== */

.c-placeholder {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    padding: 0 15px;

    @media only screen and (min-width: 768px) {
        width: 50%;
    }

    @media only screen and (min-width: 1200px) {
        width: 33.33333%;
    }
}





.c-placeholder__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 2px;
    background: $color-white;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, .01);
}





.c-placeholder__content {
    padding: 10px 8px;
}





.c-placeholder__item {
    position: relative;
    height: 17px;
    margin: 0 auto 10px;
    animation-name: placeHolderShimmer;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    border: 1px solid #f5f5f5;
    background: linear-gradient(to right, #f5f5f5 8%, #efefef 18%, #f5f5f5 33%);
    background-size: 800px 104px;
    animation-fill-mode: forwards;
}

.c-placeholder__item--title {
    height: 19px;
    margin: 5px 0 2px;
}

.c-placeholder__item--subtitle {
    height: 19px;
    width: 70%;
}

.c-placeholder__item--attributes {
    width: 50%;
}

.c-placeholder__item--price {
    width: 40%;
    height: 41px;
    margin-bottom: 20px;
}

.c-placeholder__item--button {
    height: 58px;
    margin-bottom: 0;
    background: #f5f5f5;
}

.c-placeholder__item--thin {
    height: 13px;
    margin: 0 auto 9px;
}





.c-placeholder__image {
    line-height: 0;
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 100%;
    background-color: #fafafa;
    background-image: asset-url('common/preload-logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 200px 58.5px;
}






@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
