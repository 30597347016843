.c-wine-categories__inner {
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 768px) {
        padding: 0 15px;
        max-width: 750px;
    }

    @media only screen and (min-width: 992px) {
        max-width: 970px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1170px;
    }
}

.c-wine-categories__links {
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }
}

.c-wine-categories__link {
    background: #fff;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    background: $color-white;
    border: 2px solid $color-vader;
    border-radius: 100px;
    font-family: $heading_font_family;
    font-size: 14px;
    color: $color-darkMofo;
    letter-spacing: -0.11px;
    line-height: 1;
    outline: 0;
    font-weight: bold;
    padding: 11px 20px;
    margin-right: 10px;
    margin-bottom: 25px;
    white-space: nowrap;

    @media only screen and (min-width: 992px) {
        font-size: 16px;
        margin-right: 20px;
        margin-bottom: 50px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 18px;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        color: $color-darkMofo;
        text-decoration: none;
    }
}

.c-wine-categories__link--red {
    border-color: $color-redRedWine;

    &:hover {
        background-color: rgba($color-redRedWine, 0.05);
    }

    &.is-active {
        color: $color-white;
        background-color: $color-redRedWine;
    }
}

.c-wine-categories__link--white {
    border-color: #dec8ad;

    &:hover,
    &:focus {
        background-color: rgba(#dec8ad, 0.05);
    }

    &.is-active {
        color: $color-white;
        background-color: #dec8ad;
    }
}

.c-wine-categories__link--rose-sparkling {
    border-color: #fdbebf;

    &:hover,
    &:focus {
        background-color: rgba(#fdbebf, 0.05);
    }

    &.is-active {
        color: $color-white;
        background-color: #fdbebf;
    }
}

.c-wine-categories__link--mixed {
    border-color: $color-mofoGreen;

    &:hover,
    &:focus {
        background-color: rgba($color-mofoGreen, 0.05);
    }

    &.is-active {
        color: $color-white;
        background-color: $color-mofoGreen;
    }
}

.c-wine-categories__link--black-market {
    border-color: $color-liquidGold;

    &:hover,
    &:focus {
        background-color: rgba($color-liquidGold, 0.05);
    }

    &.is-active {
        color: $color-white;
        background-color: $color-liquidGold;
    }
}
