.c-forgot-password {
    max-width: 560px;
    margin: 0 auto;
    padding: 80px 20px 0;
}





.c-forgot-password__content {
    text-align: center;
    margin-bottom: 35px;

    h1 {
        font-family: $heading_font_family;
        color: #131314;
        font-weight: bold;
        margin-bottom: 15px;
        font-size: 36px;
        letter-spacing: -0.9px;
        line-height: 40px;

        @media only screen and (min-width: 768px) {
            font-size: 48px;
            letter-spacing: -1.2px;
            line-height: 48px;
        }
    }

    p {
        font-size: 14px;
        color: #232627;
        letter-spacing: -0.4px;
        line-height: 24px;
        font-weight: normal;
        padding: 0 6%;
        margin-bottom: 0;

        @media only screen and (min-width: 768px) {
            font-size: 16px;
        }
    }
}





.c-forgot-password__form {
    padding: 25px 30px 30px;
    background-color: $color-white;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    @media only screen and (min-width: 768px) {
        padding: 45px 50px 50px;
    }
}





.c-forgot-password__submit {
    padding-top: 15px;
}
