.c-mofo-rewards-hero {
    background-image: image_url('site/loyalty-hero.png');
    background-color: #7dcee1;
    background-position: bottom -50px right -80px;
    background-repeat: no-repeat;
    height: 75vh;
    min-height: 620px;
    background-size: 985px;

    @media only screen and (max-width: 1200px) {
        background-size: 70vw;
        background-position: center right -80px;
    }

    @media only screen and (max-width: 768px) {
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        background-size: 83%;
        background-position: bottom right;
    }


    &__logo-container {
        max-width: 1000px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media only screen and (max-width: 768px) {
            justify-content: flex-start;
        }

        > * {
            margin-bottom: 20px;
        }
    }

    &__heading {
        font-family: $heading_font_family;
        font-size: 18px;
        color: white;
        letter-spacing: 1px;
        line-height: 30px;
        margin-left: 60px;

        @media only screen and (max-width: 768px) {
            margin-left: 0;
        }
    }

    &__desc {
        font-family: $heading_font_family;
        font-size: 24px;
        color: #227898;
        letter-spacing: -0.15px;
        line-height: 36px;;
        margin-left: 60px;

        @media only screen and (max-width: 768px) {
            line-height: 27px;
            margin-bottom: 25px;
            margin-left: 0;
        }
    }

    &__logo {
        max-width: 610px;

        img {
            width: 100%;
        }

        @media only screen and (max-width: 768px) {
            margin-left: 0;
            margin-bottom: 20px;
            padding: 0 30px;
        }

    }

    &__cta-container {
        margin-left: 60px;

        @media only screen and (max-width: 768px) {
            margin-left: 0;
        }

        > :first-child {
            margin-right: 20px;
        }
    }
}
